import { default as aboutEfODWYklS7Meta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/about.vue?macro=true";
import { default as balanceprojectionrtXNhhEa3RMeta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/balanceprojection.vue?macro=true";
import { default as disclosureZPAedizOBuMeta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/disclosure.vue?macro=true";
import { default as hsaguidedportfoliowm7smnaCR2Meta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/hsaguidedportfolio.vue?macro=true";
import { default as index5qMglGO0AlMeta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/index.vue?macro=true";
import { default as payrollsavingscalculatorFKAf6OX7WEMeta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/payrollsavingscalculator.vue?macro=true";
import { default as balprojcalc_45basicV3fgPp8N6hMeta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/balprojcalc-basic.vue?macro=true";
import { default as balprojcalc38Rl8tN6TKMeta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/balprojcalc.vue?macro=true";
import { default as expensesY6bRNF6wU3Meta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/glossary/expenses.vue?macro=true";
import { default as healthZ3s73saM51Meta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/glossary/health.vue?macro=true";
import { default as investmentqxIXTsJBGtMeta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/glossary/investment.vue?macro=true";
import { default as hsaeligiblexN4MIf2daZMeta } from "/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/hsaeligible.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutEfODWYklS7Meta || {},
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/about.vue")
  },
  {
    name: "balanceprojection",
    path: "/balanceprojection",
    meta: balanceprojectionrtXNhhEa3RMeta || {},
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/balanceprojection.vue")
  },
  {
    name: "disclosure",
    path: "/disclosure",
    meta: disclosureZPAedizOBuMeta || {},
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/disclosure.vue")
  },
  {
    name: "hsaguidedportfolio",
    path: "/hsaguidedportfolio",
    meta: hsaguidedportfoliowm7smnaCR2Meta || {},
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/hsaguidedportfolio.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index5qMglGO0AlMeta || {},
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/index.vue")
  },
  {
    name: "payrollsavingscalculator",
    path: "/payrollsavingscalculator",
    meta: payrollsavingscalculatorFKAf6OX7WEMeta || {},
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/payrollsavingscalculator.vue")
  },
  {
    name: "tools-balprojcalc-basic",
    path: "/tools/balprojcalc-basic",
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/balprojcalc-basic.vue")
  },
  {
    name: "tools-balprojcalc",
    path: "/tools/balprojcalc",
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/balprojcalc.vue")
  },
  {
    name: "tools-glossary-expenses",
    path: "/tools/glossary/expenses",
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/glossary/expenses.vue")
  },
  {
    name: "tools-glossary-health",
    path: "/tools/glossary/health",
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/glossary/health.vue")
  },
  {
    name: "tools-glossary-investment",
    path: "/tools/glossary/investment",
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/glossary/investment.vue")
  },
  {
    name: "tools-hsaeligible",
    path: "/tools/hsaeligible",
    component: () => import("/codebuild/output/src3567708435/src/hsaplanner-marketing/pages/tools/hsaeligible.vue")
  }
]