<script setup lang="ts">
const error = useError();

const handleError = () => {
  clearError({
    redirect: '/',
  });
};
</script>

<template>
  <div :class="['flex', 'flex-col', 'items-center', 'justify-center', 'h-screen', 'bg-gray-100 dark:bg-gray-900']">
    <h1 v-if="error" class="text-6xl font-bold text-gray-800 dark:text-white mb-8">{{ error.statusCode }} Error</h1>
    <NuxtLink
      class="focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-md text-base gap-x-2.5 shadow-sm text-white dark:text-gray-900 disabled:bg-matisse-500 dark:bg-matisse-400 dark:hover:bg-matisse-500 dark:disabled:bg-matisse-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-matisse-500 dark:focus-visible:outline-matisse-400 inline-flex items-center bg-matisse-600 hover:bg-matisse-500 py-3 px-6"
      @click="handleError">Go back home</NuxtLink>
  </div>
</template>