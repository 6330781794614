<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script setup lang="ts">
useHead({
  htmlAttrs: {
    lang: 'en',
  },
  link: [{ rel: 'icon', type: 'image/png', href: '/favicon.png' }],
})
useSeoMeta({
  title: 'HSA Planner',
  ogTitle: 'HSA Planner',
  description: 'A suite of tools, calculators and education to help healthcare account holders make informed decisions around the healthcare spending and savings.',
  ogDescription: 'A suite of tools, calculators and education to help healthcare account holders make informed decisions around the healthcare spending and savings.',
  ogImage: 'https://hsaplanner.com/favicon.png',
  twitterCard: 'summary_large_image',
  twitterSite: '@HSAPlanner',
})
</script>
